/* change navbar collapse breakpoint to 1024px
https://coderwall.com/p/wpjw4w/change-the-bootstrap-navbar-breakpoint*/
@media (max-width: 1023px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}

@media (max-width: 1023px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .navbar-default .navbar-nav>li>a {
        border: none !important;
    }

    .navbar-default .navbar-nav>li:nth-child(6) {
        border-top: solid;
        border-width: 1px;
    }

    .navbar-nav>li.dropdown {
        border-top: solid;
        border-width: 1px;
    }
}

.navbar {
    background-color: #0c2340;
    background-image: none;
    margin: 0;
    min-height: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.container {
    margin: 0;
    padding: 0;
    width: 100%;
}

.navbar-collapse {
    border: none;
    box-shadow: none;
    width: 100%;
    overflow: hidden;
}

.navbar-toggle {
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 20px;
}

.navbar-default.navbar-toggle {
    border-color: white;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: white;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:hover {
    background-color: #ddd;
}

.navbar-default .navbar-nav>li>a {
    color: white;
    padding: 0 10px 0 0;
    margin: 10px 10px 10px 0;
    
}

.navbar-default .navbar-nav>li:nth-child(1)>a,
.navbar-default .navbar-nav>li:nth-child(2)>a, 
.navbar-default .navbar-nav>li:nth-child(3)>a, 
.navbar-default .navbar-nav>li:nth-child(4)>a {
    border-right: solid;
    border-color: white;
    border-width: 1px;
}

.navbar-default .navbar-nav > li > a:hover {
    color: rgb(114, 201, 230);
    text-decoration: underline;
}

.navbar-default .navbar-nav > li > a:focus {
    color: white;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: white;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: rgb(114, 201, 230);
}

@media (min-width: 1024px) {
    .navbar {
        width: 100%;
        margin: 0;
        padding-left: calc(calc(100vw - 900px)/2);
    }

    .navbar-collapse.collapse {
        padding: 0;
    }

    #enrollLink {
        position: fixed;
        top: 15px;
        right: 100px;
        /*padding: 10px 15px;*/
    }

    #memberLevel {
        position: fixed;
        top: 35px;
        right: 15px;
        color: white;
        text-decoration: none;
        cursor: default;
    }

    #balance {
        position: fixed;
        top: 55px;
        right: 15px;
        color: white;
        text-decoration: none;
        cursor: default;
    }

    #logOut {
        position: fixed;
        top: 75px;
        right: 15px;
    }

    .nav>li.dropdown {
        position: fixed;
        top: 15px;
        right: 15px;
        z-index: 900;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #333;
    }
}
